import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Grid, Wrapper, Container, below } from "../styles"
import { Button } from "../components/Button"
import Icon from "../components/Icon"

type Props = {
  data: object
}

const SupportPage = ({ data }: Props) => {
  const supportData = [
    {
      img: data.r3Img.childImageSharp.gatsbyImageData,
      title: "Rachio 3 Smart Controller",
      items: [
        {
          iconName: "play",
          text: "Installation Video",
          link: "https://www.youtube.com/watch?v=ya8JHP-GeeY",
          tagId: "pro-support-r3-install-v",
        },
        {
          iconName: "play",
          text: "How Direct Access Works",
          link:
            "https://support.rachio.com/hc/en-us/articles/360002751154-Direct-Access-for-Rachio-Professional-Partners-",
          tagId: "pro-support-r3-how-da",
        },
        {
          iconName: "play",
          text: "Wi-Fi & App Setup",
          link: "https://www.youtube.com/watch?v=Pw71x6HWlqE",
          tagId: "pro-support-r3-wifi-setup",
        },
        {
          iconName: "play",
          text: "Wi-Fi Reset",
          link: "https://www.youtube.com/watch?v=kM81tGt9LyM",
          tagId: "pro-support-r3-wifi-reset",
        },
        {
          iconName: "play",
          text: "Weather Intelligence",
          link: "https://www.youtube.com/watch?v=m5Hni5SBHy4",
          tagId: "weather-intelligence",
        },
        {
          iconName: "pdf",
          text: "Manual",
          link:
            "https://drive.google.com/file/d/1iGahbBSWZvEvT7UlYLUMpvc4Io_ihW1g/view?usp=sharing",
          tagId: "pro-support-r3-manual",
        },
        {
          iconName: "pdf",
          text: "Spec Sheet",
          link:
            "https://drive.google.com/file/d/1dapF63lVfz6hUhj1xbTNC--hCAAdXyPE/view?usp=sharing",
          tagId: "pro-support-r3-spec",
        },
      ],
    },
    {
      img: data.enclosureImg.childImageSharp.gatsbyImageData,
      title: "Outdoor Enclosure",
      items: [
        {
          iconName: "play",
          text: "Installation Video",
          link: "https://www.youtube.com/watch?v=tvyU3jpPL08&t=1s",
          tagId: "pro-support-enc-install-v",
        },
        {
          iconName: "pdf",
          text: "Manual",
          link:
            "https://drive.google.com/open?id=16RKmvJctGjhLx5Mzm7ahR8A_4VUM5GEE",
          tagId: "pro-support-enc-manual",
        },
      ],
    },
    {
      img: data.phonesImg.childImageSharp.gatsbyImageData,
      title: "Software and Other Features",
      items: [
        {
          iconName: "play",
          text: "Schedule Overview",
          link: " https://www.youtube.com/watch?v=2lnSi5X5vY8&t=172s",
          tagId: "pro-features-schedule",
        },
        {
          iconName: "play",
          text: "Winterization",
          link: "https://www.youtube.com/watch?v=P0Olr9T8m0E&t=32s",
          tagId: "pro-features-winterization",
        },
      ],
    },
    {
      img: data.trainingImg.childImageSharp.gatsbyImageData,
      title: "Trainings",
      items: [
        {
          iconName: "play",
          text: "Zone Set Up",
          link: "https://www.youtube.com/watch?v=UAMxaVWVez0",
          tagId: "pro-trainings-zone-set-up",
        },
        {
          iconName: "play",
          text: "Schedule Best Practices",
          link: "https://www.youtube.com/watch?v=sRlo9qUOGXU",
          tagId: "pro-trainings-schedule-best-practices",
        },
        {
          iconName: "play",
          text: "Pump Start Relays & Normal Voltage Readings",
          link: "https://www.youtube.com/watch?v=4ScQuKj5Lk0",
          tagId: "pro-trainings-pump-start-relay-normal-voltage",
        },
      ],
    },
    {
      img: data.gen2Img.childImageSharp.gatsbyImageData,
      title: "Generation 2 Smart Controller",
      items: [
        {
          iconName: "play",
          text: "Installation Video",
          link: "https://www.youtube.com/watch?v=craWnm9YdK0&feature=youtu.be",
          tagId: "pro-support-g2-install-v",
        },
        {
          iconName: "play",
          text: "Wi-Fi Reset, Wi-Fi Update",
          link: "https://www.youtube.com/watch?v=CYcAGokig04&t=32s",
          tagId: "pro-support-g2-wifi-reset",
        },
        {
          iconName: "play",
          text: "Wi-Fi Setup, Troubleshooting and Lightcodes",
          link: "https://www.youtube.com/watch?v=dfV9pssQeJ4&t=52s",
          tagId: "pro-support-g2-wifi-setup",
        },
        {
          iconName: "pdf",
          text: "Manual",
          link:
            "https://drive.google.com/file/d/1lebJdde0Fmoc5rcZmrz39BSkOGeSeRyE/view?usp=sharing",
          tagId: "pro-support-g2-manual",
        },
      ],
    },
    {
      img: data.flowImg.childImageSharp.gatsbyImageData,
      title: "Wireless Flow Meter",
      items: [
        {
          iconName: "play",
          text: "Installation Video",
          link: "https://www.youtube.com/watch?v=BICEn5pR2fE&feature=youtu.be",
          tagId: "pro-support-fl-install-v",
        },
        {
          iconName: "play",
          text: "Underground Installation Video",
          link: "https://www.youtube.com/watch?v=gOX695cU03g&feature=youtu.be",
          tagId: "pro-support-fl-ug-install-v",
        },
        {
          iconName: "pdf",
          text: "Pre-install Fittings Finder",
          link: "http://rachio.com/fittings",
          tagId: "pro-support-fl-fittings",
        },
        {
          iconName: "pdf",
          text: "Manual",
          link:
            "https://drive.google.com/file/d/1MKxV1EBeXuvDc0ATNTeReIpO8EkS1j1R/view",
          tagId: "pro-support-fl-manual",
        },
        {
          iconName: "pdf",
          text: "Spec Sheet",
          link:
            "https://drive.google.com/file/d/1O9u3U3lkyYsmJLnTfC9iPZ_Qxs171MTP/view?usp=sharing",
          tagId: "pro-support-fl-spec",
        },
      ],
    },
  ]

  return (
    <>
      <SEO
        title="Dedicated Pro Support"
        description="Rachio Smart Sprinkler Controller installation videos and manuals."
        pathname="/support/"
      />
      <Wrapper>
        <h1 css="color: var(--primaryColor); text-align: center">
          Dedicated Pro Support
        </h1>
        <p className="largest" css="text-align: center">
          Find installation videos and manuals or join the active Rachio
          Community as a Rachio Pro.
        </p>
        <hr
          css={`
            padding-top: var(--basePadding);
            ${below.medium`
              margin-bottom: 6rem;
            `};
          `}
        />
        <Container>
          <h3>Top 10 Pro FAQs</h3>
          <ol
            css={`
              column-count: 2;
              li p:first-child {
                margin-top: 0;
              }
              li {
                margin-left: 1.5rem;
              }
              ${below.medium`
                column-count: 1;
              `}
            `}
          >
            {topQs.map(item => (
              <li key={item.text}>
                {item.link && (
                  <p>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <span>{item.text}</span>
                    </a>
                  </p>
                )}
                {item.links && (
                  <>
                    <p css="margin: 0">{item.text}</p>
                    <ul>
                      {item.links.map(link => (
                        <li
                          key={link.text}
                          css="list-style: disc; margin-left: 2rem"
                        >
                          <p css="margin: 1rem 0">
                            <a href={link.link} alt={link.text}>
                              {link.text}
                            </a>
                          </p>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </li>
            ))}
          </ol>
        </Container>
        <hr css="margin-bottom: var(--bottomSectionPadding)" />
        <Container>
          <Grid cols={[1, 2]}>
            {supportData.map(item => (
              <SupportItem key={item.title}>
                {item.img && (
                  <ImgWrapper>
                    <GatsbyImage image={getImage(item.img) as IGatsbyImageData} alt={item.title} />
                  </ImgWrapper>
                )}
                <h5>{item.title}</h5>
                <ul>
                  {item.items.map(i => (
                    <li key={i.tagId}>
                      <p>
                        <a
                          href={i.link}
                          id={i.tagId}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        >
                          <Icon
                            name={i.iconName}
                            color="var(--primaryColor)"
                            style={{
                              width: "24px",
                              height: "24px",
                            }}
                          />
                          {"  "}
                          <span>{i.text}</span>
                        </a>
                      </p>
                    </li>
                  ))}
                </ul>
              </SupportItem>
            ))}
          </Grid>
          <hr />
          <h3>Additional Help and Resources</h3>
          <Grid cols={[1, 1, 3]}>
            <IconCard>
              <Icon name="dialog" color="var(--primaryColor)" />
              <h6>Contact Us</h6>
              <p>
                If you are a Rachio Pro, please email us at <a href="mailto:prosupport@rachio.com">prosupport@rachio.com</a>. If you are not a pro, please email us at <a href="mailto:support@rachio.com">support@rachio.com</a>.
              </p>
              {/* <p>
                Call us toll-free at <a href="tel:844-277-6546">844-277-6546</a>{" "}
                or email us at{" "}
                <a href="mailto:prosupport@rachio.com">prosupport@rachio.com</a>.
              </p> */}
            </IconCard>
            <IconCard>
              <Icon name="tools" color="var(--primaryColor)" />
              <h6>Help Center</h6>
              <p>
                Articles and tips for existing customers using Rachio products.
              </p>
              <Button
                as="a"
                href="https://support.rachio.com"
                css="width: 100%"
              >
                Visit Site
              </Button>
            </IconCard>
            <IconCard>
              <Icon name="globe" color="var(--primaryColor)" />
              <h6>Community</h6>
              <p>
                Forum for discussing Rachio products, solutions, rebates and
                suggestions.
              </p>
              <Button
                as="a"
                href="https://community.rachio.com"
                css="width: 100%"
              >
                Visit Site
              </Button>
            </IconCard>
          </Grid>
        </Container>
      </Wrapper>
    </>
  )
}

export default SupportPage

const ImgWrapper = styled.div`
  background: var(--primaryColor);
  padding: var(--basePadding);
`

const SupportItem = styled.div`
  padding-bottom: var(--basePadding);

  ul li {
    margin-bottom: 2rem;
    a * {
      vertical-align: middle;
    }
  }
`

const IconCard = styled.div`
  background: var(--lightesterGray);
  padding: var(--basePadding);
  border-radius: var(--cardRaduis);
`

const topQs = [
  {
    text: "Offline Troubleshooting",
    link:
      "https://support.rachio.com/en_us/controller-offline-basic-wifi-tips-and-troubleshooting-SyziwUJtP",
  },
  {
    text: "Transfer Ownership",
    link:
      "https://support.rachio.com/en_us/transfer-ownership-of-a-rachio-controller-to-your-account-S1EnDIJtP",
  },
  {
    text: "Complete your Application",
    link: "https://pro.rachio.com/signup",
  },
  {
    text: "Connect Two Controllers (for larger zone counts)",
    link:
      "https://support.rachio.com/en_us/how-do-i-wire-one-master-valve-pump-or-common-wire-to-two-controllers-BkhiPIJFD",
  },
  {
    text: "Flow Meter Quick Start Guide (Installation)",
    link:
      "https://support.rachio.com/en_us/rachio-wireless-flow-meter-quick-start-guide-ByhDwLJtD",
  },
  {
    text: "Flow Meter Quick Start Guide (Pairing)",
    link:
      "https://support.rachio.com/en_us/rachio-wireless-flow-meter-quick-start-guide-ByhDwLJtD",
  },
  {
    text: "Shared Access",
    link:
      "https://support.rachio.com/en_us/how-to-use-complete-(formerly-shared)-access-BkKav8JKw",
  },
  {
    text: "Where can I download the app",
    link:
      "https://support.rachio.com/en_us/where-can-i-download-the-app-HJodD8JYD",
  },
  {
    text: "Tech Specs",
    links: [
      {
        text: "Gen 2",
        link:
          "https://support.rachio.com/en_us/generation-2-tech-specs-HkkvDIJFD",
      },
      {
        text: "Rachio 3",
        link:
          "https://support.rachio.com/en_us/rachio-3-compatibility-and-tech-specs-H1q8P8JtP",
      },
      {
        text: "Outdoor Enclosure",
        link:
          "https://support.rachio.com/en_us/outdoor-enclosure-tech-specs-S1xDDUyYv",
      },
    ],
  },
  {
    text: "Direct Access",
    link:
      "https://support.rachio.com/en_us/direct-access-for-rachio-professional-partners-BkZW_Iktw",
  },
]

export const query = graphql`
  query {
    gen2Img: file(relativePath: { eq: "products/gen2/gen2-front.png" }) {
      ...smallFluidImage
    }
    r3Img: file(relativePath: { eq: "products/r3/r3-front.png" }) {
      ...smallFluidImage
    }
    flowImg: file(relativePath: { eq: "products/flow/flow.png" }) {
      ...smallFluidImage
    }
    enclosureImg: file(
      relativePath: { eq: "products/enclosure/enclosure.png" }
    ) {
      ...smallFluidImage
    }
    phonesImg: file(relativePath: { eq: "products/phones.png" }) {
      ...smallFluidImage
    }
    trainingImg: file(relativePath: {eq: "logos/logo-crp.png" }) {
      ...smallFluidImage
    }
  }
`
